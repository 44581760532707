<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'

  const route = useRoute()

  type ImageName =
    | 'page-forbidden.svg'
    | 'page-not-found.svg'
    | 'maintenance.svg'
    | 'no-comment.svg'
    | 'no-customer.svg'
    | 'no-notification.svg'
    | 'no-project.svg'
    | 'no-quotation.svg'
    | 'no-reminder.svg'
    | 'no-result.svg'
    | 'no-data.svg'

  const props = defineProps<{
    image: ImageName
    title?: string
  }>()

  const filename = computed(() => (route.query.cari ? 'no-result.svg' : props.image))
  const src = computed(() => '/images/empty/' + filename.value)
  const alt = computed(() => {
    switch (filename.value) {
      case 'page-forbidden.svg':
        return 'Akses Ditolak'
      case 'page-not-found.svg':
        return 'Halaman Tidak Ditemukan'
      case 'maintenance.svg':
        return 'Sedang Dalam Perbaikan'
      case 'no-comment.svg':
        return 'Belum Ada Komentar'
      case 'no-customer.svg':
        return 'Belum Ada Pelanggan'
      case 'no-notification.svg':
        return 'Belum Ada Notifikasi'
      case 'no-project.svg':
        return 'Belum Ada Proyek'
      case 'no-quotation.svg':
        return 'Belum Ada Penawaran'
      case 'no-reminder.svg':
        return 'Belum Ada Pengingat'
      case 'no-data.svg':
        return 'Tidak Ada Data'
      case 'no-result.svg':
        return 'Tidak Ada Hasil'
      default:
        return 'Gambar'
    }
  })
</script>

<template>
  <div class="p-4">
    <img
      :src="src"
      :alt="alt"
      class="mx-auto size-80 max-w-sm w-full -mb-8 sm:-mb-16 sm:-mt-8"
    />
    <div
      v-if="title"
      class="text-center text-primary font-bold sm:text-2xl"
    >
      {{ route.query.cari ? 'Pencarian Tidak Ditemukan' : title }}
    </div>
  </div>
</template>
