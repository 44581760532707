<script setup lang="ts">
  import TheToast from '@/components/the-toast.vue'
  import ErrorMaintenance from '@/views/errors/error-503.vue'
  import { useDashboard } from './models/dashboard'
  import { useAppStore } from './stores/app'
  import { useAuthStore } from './stores/auth'

  const auth = useAuthStore()
  const app = useAppStore()

  const { init } = useDashboard()

  init()
</script>

<template>
  <app-router-view v-if="auth.authenticated >= 0" />
  <the-toast />
  <error-maintenance v-if="app.maintenance" />
</template>
