<script setup lang="ts">
  import { Menu as AppMenu, MenuButton, MenuItems } from '@headlessui/vue'
  import { computed } from 'vue'

  const props = defineProps<{
    title?: string
    position?: 'top left' | 'top right' | 'bottom left' | 'bottom right'
  }>()

  const computedPosition = computed(() => {
    return props.position ?? 'bottom right'
  })
</script>

<template>
  <app-menu
    as="div"
    class="relative inline-block"
  >
    <menu-button
      class="block"
      :title="title ?? 'Klik untuk menampilkan menu'"
    >
      <slot></slot>
    </menu-button>

    <transition name="scale">
      <menu-items
        class="menu-items card"
        :class="{
          'menu-items-right': computedPosition.includes('right'),
          'menu-items-left': computedPosition.includes('left'),
          'menu-items-bottom': computedPosition.includes('bottom'),
          'menu-items-top': computedPosition.includes('top'),
        }"
      >
        <slot name="items"></slot>
      </menu-items>
    </transition>
  </app-menu>
</template>

<style lang="postcss" scoped>
  .menu-items {
    @apply absolute focus:outline-none overflow-hidden text-sm z-10;
  }
  .menu-items-right {
    @apply right-0;
  }
  .menu-items-left {
    @apply left-0;
  }
  .menu-items-bottom {
    @apply mt-2;
  }
  .menu-items-top {
    @apply bottom-full mb-2;
  }
</style>
