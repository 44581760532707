export default [
  {
    path: 'hak-akses',
    name: 'role list',
    component: () => import('@/views/app/setting/role/page-list.vue'),
    meta: { title: 'Pengaturan Hak Akses' },
  },
  {
    path: 'akun-saya',
    name: 'account setting',
    component: () => import('@/views/app/setting/my-account/page-my-account.vue'),
    meta: { title: 'Pengaturan Akun Saya' },
  },
  {
    path: 'password-baru',
    name: 'set new password',
    component: () => import('@/views/app/setting/page-new-password.vue'),
    meta: { title: 'Selamat Datang' },
  },
]
