import { type App } from 'vue'
import AppBadge from './app-badge.vue'
import AppButtonBack from './app-button-back.vue'
import AppButton from './app-button.vue'
import AppChart from './app-chart.vue'
import AppCheckbox from './app-checkbox.vue'
import AppDatepicker from './app-datepicker.vue'
import AppDropZone from './app-drop-zone.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppFormSection from './app-form-section.vue'
import AppForm from './app-form.vue'
import AppImgCropper from './app-image-cropper.vue'
import AppImgEmpty from './app-image-empty.vue'
import AppImg from './app-image.vue'
import AppImgWp from './app-img-wp.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppNotice from './app-notice.vue'
import AppPagination from './app-pagination.vue'
import AppRouterView from './app-router-view.vue'
import AppSearchPage from './app-search-page.vue'
import AppSearch from './app-search.vue'
import AppSelect from './app-select.vue'
import AppSubmit from './app-submit.vue'
import AppSwitch from './app-switch.vue'
import AppTextShowMore from './app-text-show-more.vue'
import AppUserPhotoCropper from './app-user-photo-cropper.vue'
import AppUserPhoto from './app-user-photo.vue'
import UseCard from './card'
import useDropdown from './dropdown'
import FaIcon from './fa-icon.vue'
import useInput from './input'
import UseMarkdown from './markdown'
import UseModal from './modal'
import AppSubmenuItem from './submenu/app-submenu-item.vue'
import AppSubmenu from './submenu/app-submenu.vue'
import AppTableCheckable from './table/app-table-checkable.vue'
import AppTable from './table/app-table.vue'

export default {
  install(app: App) {
    app.component('AppBadge', AppBadge)
    app.component('AppButton', AppButton)
    app.component('AppButtonBack', AppButtonBack)
    app.component('AppChart', AppChart)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppDropZone', AppDropZone)
    app.component('AppDatepicker', AppDatepicker)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppForm', AppForm)
    app.component('AppFormSection', AppFormSection)
    app.component('AppImgWp', AppImgWp)
    app.component('AppImg', AppImg)
    app.component('AppImgEmpty', AppImgEmpty)
    app.component('AppImgCropper', AppImgCropper)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppNotice', AppNotice)
    app.component('AppPagination', AppPagination)
    app.component('AppRouterView', AppRouterView)
    app.component('AppSearch', AppSearch)
    app.component('AppSearchPage', AppSearchPage)
    app.component('AppSelect', AppSelect)
    app.component('AppSubmenu', AppSubmenu)
    app.component('AppSubmenuItem', AppSubmenuItem)
    app.component('AppSubmit', AppSubmit)
    app.component('AppSwitch', AppSwitch)
    app.component('AppTable', AppTable)
    app.component('AppTableCheckable', AppTableCheckable)
    app.component('AppTextShowMore', AppTextShowMore)
    app.component('AppUserPhoto', AppUserPhoto)
    app.component('AppUserPhotoCropper', AppUserPhotoCropper)
    app.component('FaIcon', FaIcon)
    app.use(useDropdown)
    app.use(UseModal)
    app.use(UseCard)
    app.use(useInput)
    app.use(UseMarkdown)
  },
}

declare module 'vue' {
  export interface GlobalComponents {
    AppBadge: typeof AppBadge
    AppButton: typeof AppButton
    AppButtonBack: typeof AppButtonBack
    AppChart: typeof AppChart
    AppCheckbox: typeof AppCheckbox
    AppDatepicker: typeof AppDatepicker
    AppDropZone: typeof AppDropZone
    AppErrorMsg: typeof AppErrorMsg
    AppForm: typeof AppForm
    AppFormSection: typeof AppFormSection
    AppImgWp: typeof AppImgWp
    AppImg: typeof AppImg
    AppImgEmpty: typeof AppImgEmpty
    AppImgCropper: typeof AppImgCropper
    AppLoadingDot: typeof AppLoadingDot
    AppNotice: typeof AppNotice
    AppPagination: typeof AppPagination
    AppRouterView: typeof AppRouterView
    AppSearch: typeof AppSearch
    AppSearchPage: typeof AppSearchPage
    AppSelect: typeof AppSelect
    AppSubmenu: typeof AppSubmenu
    AppSubmenuItem: typeof AppSubmenuItem
    AppSubmit: typeof AppSubmit
    AppSwitch: typeof AppSwitch
    AppTable: typeof AppTable
    AppTableCheckable: typeof AppTableCheckable
    AppTextShowMore: typeof AppTextShowMore
    AppUserPhoto: typeof AppUserPhoto
    AppUserPhotoCropper: typeof AppUserPhotoCropper
    FaIcon: typeof FaIcon
  }
}
