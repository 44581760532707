<script setup lang="ts">
  import Highcharts from 'highcharts'
  import { Chart as HighchartVue } from 'highcharts-vue'
  import accessibility from 'highcharts/modules/accessibility'
  import exportingInit from 'highcharts/modules/exporting'

  exportingInit(Highcharts)
  accessibility(Highcharts)

  defineProps<{
    // constructorType: 'stockChart' | 'chart' | 'mapChart'
    options: Highcharts.Options
  }>()
</script>

<template>
  <div class="card">
    <div class="p-2">
      <highchart-vue :options="options" />
    </div>
  </div>
</template>
