<script setup lang="ts">
  import { computed, ref, watchEffect } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { fmt } from '@/functions'

  const route = useRoute()
  const router = useRouter()

  const props = defineProps<{
    data?: Pagination
  }>()

  const currentPage = ref<number>(Number(route.query.hlm ?? 1))
  const lastPage = computed(() => props.data?.last_page ?? 1)
  const prevLink = computed(() => {
    if (currentPage.value <= 1) {
      return
    }
    const prevPage = currentPage.value === 2 ? undefined : currentPage.value - 1
    return { ...route, query: { ...route.query, hlm: prevPage } }
  })
  const nextLink = computed(() => {
    if (currentPage.value >= lastPage.value) {
      return
    }
    return { ...route, query: { ...route.query, hlm: currentPage.value + 1 } }
  })

  const pages = computed(() => {
    if (lastPage.value) {
      return Array.from({ length: lastPage.value }, (_, i) => i + 1)
    }
    return [1]
  })

  watchEffect(() => {
    const newPage = currentPage.value === 1 ? undefined : currentPage.value
    router.push({ ...route, query: { ...route.query, hlm: newPage } })
  })
  watchEffect(() => {
    currentPage.value = Number(route.query.hlm ?? 1)
    window.scrollTo({ top: 0 })
  })
</script>

<template>
  <nav
    v-if="data && lastPage > 1"
    role="navigation"
    aria-label="Pagination Navigation"
  >
    <div class="my-4 flex items-center justify-end md:justify-between">
      <div class="hidden text-xs md:block">
        Menampilkan
        <span class="font-semibold">{{ fmt.number(data.from) }}-{{ fmt.number(data.to) }}</span>
        dari
        <span class="font-semibold">{{ fmt.number(data.total) }}</span>
      </div>
      <div class="flex items-center text-sm">
        <router-link
          v-if="prevLink"
          :to="prevLink"
          class="mr-4 text-primary-500"
        >
          Kembali
        </router-link>
        <div>
          <app-select v-model="currentPage">
            <option
              v-for="p in pages"
              :key="p"
              :value="p"
            >
              {{ fmt.number(p) }}
            </option>
          </app-select>
        </div>
        <div class="ml-2 mr-4">/</div>
        <div>
          {{ fmt.number(lastPage) }}
        </div>
        <div>
          <router-link
            v-if="nextLink"
            :to="nextLink"
            class="ml-4 text-primary-500"
          >
            Lanjut
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>
