<script setup lang="ts">
  import { useAppModal } from '@/components/modal'

  defineProps<{
    filterCount?: number
    title: string
  }>()
  const emit = defineEmits<{
    (e: 'reset'): void
    (e: 'apply'): void
  }>()

  const { modal, open, close } = useAppModal()
  const apply = () => {
    emit('apply')
    close()
  }
</script>

<template>
  <div class="mb-4 flex items-center justify-end gap-2 sm:gap-4">
    <slot name="before"></slot>

    <app-button
      class="btn-low-priority btn-icon relative"
      @click="open()"
    >
      <fa-icon
        :icon="(filterCount ?? 0) > 0 ? 'i-fas-filter' : 'i-far-filter'"
        class="text-gray-500"
      />
      <span
        v-if="(filterCount ?? 0) > 0"
        class="absolute size-5 flex-center rounded-full bg-primary-600 text-2.5 text-white font-bold -right-2 -top-2"
      >
        {{ filterCount }}
      </span>
    </app-button>

    <slot name="after"></slot>

    <app-modal
      ref="modal"
      :title="title"
    >
      <div class="mb-8 space-y-4">
        <slot name="filter-form"></slot>
      </div>

      <app-modal-footer>
        <app-button
          class="btn-low-priority"
          @click="emit('reset')"
        >
          Reset
        </app-button>
        <app-button
          class="btn-primary"
          @click="apply"
        >
          Terapkan
        </app-button>
      </app-modal-footer>
    </app-modal>
  </div>
</template>
