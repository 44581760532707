<script setup lang="ts">
  import { computed } from 'vue'

  interface ObjectWithId extends Record<string, unknown> {
    id: number
  }

  const props = defineProps<{
    data: ObjectWithId[]
    modelValue: number[]
    disabled?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: number[]): void
  }>()

  const selected = computed({
    set: (value) => {
      emit('update:modelValue', value)
    },
    get: () => props.modelValue,
  })
  const selectedAll = computed({
    set: (value) => {
      if (value) {
        selected.value = props.data.map((data) => data.id)
      } else {
        selected.value = []
      }
    },
    get: () =>
      selected.value.length > 0 && props.data.every((data) => selected.value.includes(data.id)),
  })
  const selectAllIndeterminate = computed(() => {
    const selectedSome = selected.value.length > 0
    const notAllSelected = selected.value.length < props.data.length
    return selectedSome && notAllSelected
  })
</script>

<template>
  <app-table class="checkable">
    <thead>
      <th
        v-if="!disabled"
        class="w-px text-center"
      >
        <app-checkbox
          v-model="selectedAll"
          :indeterminate="selectAllIndeterminate"
        />
      </th>
      <slot name="thead"></slot>
    </thead>
    <slot name="tbody">
      <tbody>
        <tr
          v-for="(item, index) in data"
          :key="item.id"
        >
          <td
            v-if="!disabled"
            class="w-px"
          >
            <app-checkbox
              v-model="selected"
              :value="item.id"
            />
          </td>
          <slot
            :item="item"
            :index="index"
          ></slot>
        </tr>
      </tbody>
    </slot>
  </app-table>
</template>

<style lang="postcss" scoped>
  .checkable :deep() table {
    th:first-child,
    td:first-child {
      @apply px-2;

      label {
        @apply relative h-8 w-8 justify-center rounded-full;

        div {
          @apply hidden;
        }
      }

      input[type='checkbox']:enabled:hover::before {
        @apply content-empty bg-primary-700 absolute inset-0 block rounded-full opacity-20;
      }
    }
  }
</style>
