import { defineStore } from 'pinia'

export const useSearchGlobalStore = defineStore('SearchGlobal', {
  state: () => ({
    search: '',
    admins: <SearchUser[]>[],
    customers: <SearchUser[]>[],
    technicians: <SearchUser[]>[],
    projects: <SearchProject[]>[],
    projectRequests: <SearchProjectRequest[]>[],
    loading: false,
  }),
  actions: {
    //
  },
})
