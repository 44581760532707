import { defineStore } from 'pinia'
import { useApi } from '@/functions/api'

type State = {
  user: AuthenticatedUser
  deviceKey: string
  authenticated: -1 | 0 | 1 // 0 = false, 1 = true, -1 = unknown
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    deviceKey: '',
    user: {
      id: 0,
      email: '',
      name: '',
      permissions: [],
      role: { id: 0, name: '' },
      username: '',
      photo_url: null,
      photo_url_xs: null,
      require_new_password: false,
    },
    authenticated: -1,
  }),
  getters: {
    userCan: (state) => (permission: string) => {
      return state.user?.permissions.some((p) => p.toLowerCase() === permission.toLowerCase())
    },
  },
  actions: {
    setUser(user: Partial<AuthenticatedUser>) {
      this.user = { ...this.user, ...user }
    },
    setEmail(email: string) {
      if (this.user) {
        this.user.email = email
      }
    },
    setAuthenticated(authenticated: -1 | 0 | 1) {
      this.authenticated = authenticated
    },
    setDeviceKey(key: string) {
      this.deviceKey = key
      const api = useApi()
      api.POST('notification/device-key', { device_key: key })
    },
  },
})
