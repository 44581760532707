export default [
  {
    path: '',
    name: 'user admin list',
    component: () => import('@/views/app/setting/admin/list/page-list.vue'),
    meta: { title: 'Daftar Pengguna Internal' },
  },
  {
    path: 'baru',
    name: 'user admin create',
    component: () => import('@/views/app/setting/admin/create/page-create.vue'),
    meta: { title: 'Tambah Pengguna Internal Baru' },
  },
  {
    path: ':id/edit',
    name: 'user admin edit',
    component: () => import('@/views/app/setting/admin/edit/page-edit.vue'),
    meta: { title: 'Edit Pengguna Internal' },
  },
  {
    path: ':id/edit-password',
    name: 'user admin edit password',
    component: () => import('@/views/app/setting/admin/edit/page-edit-password.vue'),
    meta: { title: 'Edit Password Pengguna Internal' },
  },
]
