import Cropper from 'cropperjs'
import { nextTick, ref, useTemplateRef } from 'vue'

export const useImgCropper = () => {
  const cropper = ref<Cropper>()
  const canvasWidth = ref(0)
  const canvasHeight = ref(0)
  const scaleX = ref(1)
  const scaleY = ref(1)
  const elImage = useTemplateRef<HTMLImageElement>('elImage')

  const initCropper = async (
    width: number,
    height: number,
    canvas?: Cropper.CanvasData,
    fitMode?: 'fill' | 'contain',
  ) => {
    canvasWidth.value = width
    canvasHeight.value = height

    return new Promise<void>((resolve) => {
      if (!elImage.value) return

      cropper.value = new Cropper(elImage.value, {
        dragMode: 'move',
        cropBoxMovable: false,
        cropBoxResizable: false,
        aspectRatio: width / height,
        guides: false,
        viewMode: 0,
        // minCanvasHeight: height,
        // minCanvasWidth: width,
        minCropBoxWidth: width,
        minCropBoxHeight: height,
        // responsive: false,
        checkOrientation: false,
        checkCrossOrigin: false,
        ready: () => {
          const container = document.querySelector<HTMLElement>('.cropper-container')
          if (!container) return
          container.style.width = '100%'
          container.style.height = '100%'
          container.style.maxWidth = `${width}px`
          container.style.aspectRatio = (width / height).toString()

          cropper.value?.setCropBoxData({ width, height, left: 0, top: 0 })
          nextTick(() => {
            if (canvas) {
              cropper.value?.setCanvasData(canvas)
            } else if (fitMode === 'fill') {
              autoFitFill()
            } else {
              autoFitContain()
            }
            resolve()
          })
        },
      })
    })
  }

  const getCroppedCanvas = () => {
    return cropper.value?.getCroppedCanvas({
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    })
  }
  const getCroppedBlob = () => {
    return new Promise<Blob | undefined>((resolve) => {
      const croppedCanvas = getCroppedCanvas()
      if (!croppedCanvas) return resolve(undefined)

      const type = 'image/png'
      const quality = 1
      croppedCanvas.toBlob(
        (blob) => {
          resolve(blob ?? undefined)
        },
        type,
        quality,
      )
    })
  }
  const getCroppedSrc = () => {
    const quality = 1
    return getCroppedCanvas()?.toDataURL('image/webp', quality)
  }
  const getCanvasData = () => {
    return cropper.value?.getCanvasData()
  }

  // image manipulations
  const destroy = () => {
    if (!cropper.value) return
    cropper.value.destroy()
  }
  const rotate = (degree: number) => {
    if (!cropper.value) return
    cropper.value.rotate(degree)
  }
  const flipHorizontal = () => {
    if (!cropper.value) return
    scaleX.value *= -1
    cropper.value.scale(scaleX.value, scaleY.value)
  }
  const flipVertical = () => {
    if (!cropper.value) return
    scaleY.value *= -1
    cropper.value.scale(scaleX.value, scaleY.value)
  }
  const alignHorizontalLeft = () => {
    if (!cropper.value) return
    cropper.value.setCanvasData({ left: 0 })
  }
  const alignHorizontalCenter = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const left = (cropBoxData.width - canvasData.width) / 2
    cropper.value.setCanvasData({ left })
  }
  const alignHorizontalRight = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const left = cropBoxData.width - canvasData.width
    cropper.value.setCanvasData({ left })
  }
  const alignVerticalTop = () => {
    if (!cropper.value) return
    cropper.value.setCanvasData({ top: 0 })
  }
  const alignVerticalMiddle = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const top = (cropBoxData.height - canvasData.height) / 2
    cropper.value.setCanvasData({ top })
  }
  const alignVerticalBottom = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const top = cropBoxData.height - canvasData.height
    cropper.value.setCanvasData({ top })
  }
  const fitToHeight = () => {
    if (!cropper.value) return
    const { height } = cropper.value.getCropBoxData()
    cropper.value.setCanvasData({ height: Math.max(height) })
    alignVerticalTop()
  }
  const fitToWidth = () => {
    if (!cropper.value) return
    const { width } = cropper.value.getCropBoxData()
    cropper.value.setCanvasData({ width: Math.max(width) })
    alignHorizontalLeft()
  }
  const autoFitFill = () => {
    if (!cropper.value) return
    const { width, height } = cropper.value.getCanvasData()
    const aspectRatio = canvasWidth.value / canvasHeight.value
    if (width / height < aspectRatio) {
      fitToWidth()
    } else {
      fitToHeight()
    }
    alignVerticalMiddle()
    alignHorizontalCenter()
  }
  const autoFitContain = () => {
    if (!cropper.value) return
    const { width, height } = cropper.value.getCanvasData()
    const aspectRatio = canvasWidth.value / canvasHeight.value
    if (width / height > aspectRatio) {
      fitToWidth()
    } else {
      fitToHeight()
    }
    alignVerticalMiddle()
    alignHorizontalCenter()
  }

  return {
    cropper,
    initCropper,
    getCroppedBlob,
    getCroppedSrc,
    getCanvasData,
    destroy,
    rotate,
    flipHorizontal,
    flipVertical,
    alignHorizontalLeft,
    alignHorizontalCenter,
    alignHorizontalRight,
    alignVerticalTop,
    alignVerticalMiddle,
    alignVerticalBottom,
    fitToHeight,
    fitToWidth,
    autoFitFill,
    autoFitContain,
  }
}

export const base64ToBlob = async (base64Image: string) => {
  const res = await fetch(base64Image)
  return await res.blob()
}

export const filereader = (file: File) => {
  return new Promise<string | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      if (e.target === null) return reject(null)
      if (!e.target.result) return reject(null)
      const imageBase64 = String(e.target.result)
      resolve(imageBase64)
    }
  })
}
