import type { App } from 'vue'
import AppCardBtnFilter from './app-card-btn-filter.vue'
import AppCardFilterBar from './app-card-filter-bar.vue'
import AppCard from './app-card.vue'

export default {
  install(app: App) {
    app.component('AppCard', AppCard)
    app.component('AppCardFilterBar', AppCardFilterBar)
    app.component('AppCardBtnFilter', AppCardBtnFilter)
  },
}
declare module 'vue' {
  export interface GlobalComponents {
    AppCard: typeof AppCard
    AppCardFilterBar: typeof AppCardFilterBar
    AppCardBtnFilter: typeof AppCardBtnFilter
  }
}
