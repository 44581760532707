export default [
  {
    path: '',
    component: () => import('@/views/app/customer/list/page-list.vue'),
    name: 'customer list',
    meta: { title: 'Daftar Pelanggan' },
  },
  {
    path: ':id',
    component: () => import('@/views/app/customer/show/page-show.vue'),
    name: 'customer show',
    meta: { title: 'Detail Pelanggan' },
  },
]
