<script setup lang="ts">
  import { useModal } from '.'

  defineProps<{
    title?: string
    description?: string
    size?: 'small' | 'medium' | 'large'
  }>()

  const { isOpen, open, close } = useModal()

  defineExpose({ open, close })
</script>

<template>
  <teleport to="body">
    <!-- dark overlay -->
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="isOpen"
        class="fixed inset-0 z-50 bg-black bg-opacity-25"
        @click="isOpen = false"
      ></div>
    </transition>

    <transition
      name="slide-down"
      mode="out-in"
    >
      <div
        v-if="isOpen"
        class="pointer-events-none fixed inset-0 z-60 flex-center p-2 sm:p-4"
      >
        <div
          class="dialog-panel"
          :class="{
            'max-w-4xl': size === 'large',
            'max-w-xl': size === 'medium',
            'max-w-100': !['large', 'medium'].includes(size ?? 'small'),
          }"
        >
          <app-button
            class="absolute right-4 top-5 h-8 w-8 flex-center rounded-full text-gray-500 hover:bg-gray-200"
            @click="close"
          >
            <fa-icon icon="i-fas-xmark" />
          </app-button>

          <app-modal-title v-if="title">
            {{ title }}
          </app-modal-title>

          <div class="dialog-content">
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style lang="postcss" scoped>
  .dialog-panel {
    @apply transform rounded-2xl bg-white p-6 pb-20 text-left align-middle shadow-xl transition-all w-full pointer-events-initial;
  }
  .dialog-content {
    /* ensure the modal content height will not surpass screen height */
    @apply flex overflow-y-auto flex-col max-h-[calc(100vh_-_8rem)] lg:max-h-[calc(100vh_-_12rem)] 2xl:max-h-[calc(100vh_-_16rem)];
    @apply p-1 pr-2.5 scrollbar-stable -m-1 -mr-4;
  }
</style>
