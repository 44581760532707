<script setup lang="ts">
  import { nextTick, ref } from 'vue'
  import { useAppModal } from '@/components/modal'
  import { useImgCropper } from '@/functions/img'

  const props = defineProps<{
    width: number
    aspectX: number
    aspectY: number
    title: string
    id?: string
    fitMode?: 'contain' | 'fill'
  }>()

  const emit = defineEmits<{
    (e: 'save', croppedSrc: string | undefined, canvasData?: Cropper.CanvasData): void
  }>()

  const {
    initCropper,
    getCroppedSrc,
    getCanvasData,
    rotate,
    flipHorizontal,
    flipVertical,
    alignHorizontalLeft,
    alignHorizontalCenter,
    alignHorizontalRight,
    alignVerticalTop,
    alignVerticalMiddle,
    alignVerticalBottom,
    fitToHeight,
    fitToWidth,
    autoFitFill,
    autoFitContain,
  } = useImgCropper()

  const elImageSrc = ref('')

  const saving = ref(false)
  const saveCrop = () => {
    saving.value = true
    emit('save', getCroppedSrc(), getCanvasData())
    close()
  }
  const { modal, open, close } = useAppModal((src: string, canvas?: Cropper.CanvasData) => {
    saving.value = false
    elImageSrc.value = src
    nextTick(() => {
      nextTick(() => {
        // wait until the img src set before initiating the cropperjs
        initCropper(
          props.width,
          (props.width * props.aspectY) / props.aspectX,
          canvas,
          props.fitMode,
        )
      })
    })
  })

  defineExpose({ open })
</script>

<template>
  <app-modal
    ref="modal"
    :title="title"
  >
    <div
      :id="id"
      class="flex justify-center"
    >
      <img
        ref="elImage"
        class="w-full"
        :style="{ aspectRatio: aspectX + '/' + aspectY, maxWidth: `${width}px` }"
        :src="elImageSrc"
      />
    </div>

    <div class="my-5 space-y-4">
      <div class="flex-center">
        <app-button
          class="btn-low-priority btn-icon mr-1"
          title="Rotate Left"
          @click="rotate(-90)"
        >
          <fa-icon icon="i-far-rotate-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4"
          title="Rotate Right"
          @click="rotate(90)"
        >
          <fa-icon icon="i-far-rotate-right" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Flip Horizontal"
          @click="flipHorizontal"
        >
          <fa-icon icon="i-far-arrow-right-arrow-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4 rotate-90"
          title="Flip Vertical"
          @click="flipVertical"
        >
          <fa-icon icon="i-far-arrow-right-arrow-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Fit To Width"
          @click="fitToWidth"
        >
          <fa-icon icon="i-far-arrows-left-right-to-line" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4 rotate-90"
          title="Fit To Height"
          @click="fitToHeight"
        >
          <fa-icon icon="i-far-arrows-left-right-to-line" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Auto Fit (Contain)"
          @click="autoFitContain"
        >
          <fa-icon icon="i-far-compress" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Auto Fit (Fill)"
          @click="autoFitFill"
        >
          <fa-icon icon="i-far-expand" />
        </app-button>
      </div>

      <div class="flex-center">
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Horizontal Left"
          @click="alignHorizontalLeft"
        >
          <fa-icon icon="i-far-objects-align-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Horizontal Center"
          @click="alignHorizontalCenter"
        >
          <fa-icon icon="i-far-objects-align-center-horizontal" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4"
          title="Align Horizontal Right"
          @click="alignHorizontalRight"
        >
          <fa-icon icon="i-far-objects-align-right" />
        </app-button>

        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Vertical Top"
          @click="alignVerticalTop"
        >
          <fa-icon icon="i-far-objects-align-top" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Vertical Middle"
          @click="alignVerticalMiddle"
        >
          <fa-icon icon="i-far-objects-align-center-vertical" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority"
          title="Align Vertical Bottom"
          @click="alignVerticalBottom"
        >
          <fa-icon icon="i-far-objects-align-bottom" />
        </app-button>
      </div>
    </div>

    <app-modal-footer>
      <app-button
        class="btn-primary"
        :loading="saving"
        @click="saveCrop"
      >
        Simpan
      </app-button>
      <app-button
        class="btn-low-priority"
        @click="close"
      >
        Batal
      </app-button>
    </app-modal-footer>
  </app-modal>
</template>

<style lang="postcss" scoped>
  .btn-icon {
    @apply flex-shrink-0 !p-0 !text-xs !size-7 sm:!size-9;
  }
</style>
