<script setup lang="ts"></script>

<template>
  <div class="max-xl:overflow-x-auto max-xl:overflow-y-hidden">
    <!-- disable overflow on desktop so that sticky header can work -->
    <table class="app-table w-full">
      <slot></slot>
    </table>
  </div>
</template>

<style lang="postcss" scoped>
  .app-table :deep() {
    @apply max-md:text-sm;

    thead {
      @apply z-1 top-16 bg-white xl:sticky;
      /* border-b not working, need to use box-shadow */
      @apply shadow-[inset_0_-1px_0_rgba(0,0,0,1)] shadow-gray-200;
    }

    th {
      @apply py-3 align-bottom font-bold;
    }

    th,
    td {
      @apply px-2 first:pl-0 last:pr-0 md:px-4;
    }

    td {
      @apply border-b py-3;

      .clickable {
        @apply decoration-blue text-left underline decoration-dotted decoration-1 underline-offset-4;
      }

      &.buttons {
        @apply text-right;
      }

      strong {
        @apply font-semibold;
      }
    }

    tr:last-of-type td {
      @apply border-b-0;
    }
  }
</style>
