<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt } from '@/functions'

  // File when in form, string when getting from server
  const props = defineProps<{
    file: File | Attachment | AttachmentUploading
    canDelete?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'remove'): void
    (e: 'click'): void
  }>()

  const src = computed(() => {
    if ('progress' in props.file) {
      return ''
    }
    if ('filetype' in props.file === false) {
      // check if props.file is File
      // type File
      return URL.createObjectURL(props.file)
    }
    // type Attachment
    if ('thumbnail_xs' in props.file.url) {
      return props.file.url.thumbnail_xs
    }
    // type Gif
    if ('default' in props.file.url && props.file.url.default.endsWith('.gif')) {
      return props.file.url.default
    }
    // type Attachment other
    return ''
  })

  const isFileImage = computed(() => {
    if ('progress' in props.file) {
      return false
    }
    if ('filetype' in props.file === false) {
      // check if props.file is File
      return props.file.type.startsWith('image/')
    }
    if ('default' in props.file.url && props.file.url.default.endsWith('.gif')) {
      return true
    }
    return props.file.filetype === 'image'
  })
  const isFileVideo = computed(() => {
    if ('progress' in props.file) {
      return false
    }
    if ('filetype' in props.file === false) {
      // check if props.file is File
      return props.file.type.startsWith('video/')
    }
    return props.file.filetype === 'video'
  })
  const isFileAudio = computed(() => {
    if ('progress' in props.file) {
      return false
    }
    if ('filetype' in props.file === false) {
      // check if props.file is File
      return props.file.type.startsWith('audio/')
    }
    return props.file.filetype === 'audio'
  })
  const isFilePdf = computed(() => {
    if ('progress' in props.file) {
      return false
    }
    if ('filetype' in props.file === false) {
      // check if props.file is File
      return props.file.name.endsWith('.pdf')
    }
    return props.file.filename.endsWith('.pdf')
  })
  const filename = computed(() => {
    if ('filetype' in props.file === false) {
      // check if props.file is File
      return props.file.name
    } else {
      return props.file.filename
    }
  })
  const isFileCompressed = computed(() => {
    const extensions = ['.zip', '.rar', '.7z']
    return extensions.some((ext) => filename.value.endsWith(ext))
  })
</script>

<template>
  <div class="relative flex flex-col">
    <app-button
      v-if="'progress' in file === false"
      class="relative block size-32 overflow-hidden rounded ring-1 ring-black ring-opacity-10 drop-shadow"
      @click="emit('click')"
    >
      <template v-if="isFileImage || (isFileVideo && 'filetype' in file)">
        <!-- if file is Attachment, just show the thumbnail -->
        <img
          :src="src"
          class="size-full object-cover"
        />
      </template>
      <template v-else-if="isFileVideo">
        <video
          :src="src"
          class="size-full object-cover"
        />
      </template>
      <template v-else>
        <div class="size-full flex-center flex-col gap-1 bg-white p-2 text-gray-400">
          <div class="pt-2 text-3xl">
            <fa-icon
              v-if="isFileCompressed"
              icon="i-fal-file-zip"
            />
            <fa-icon
              v-else-if="isFilePdf"
              icon="i-fal-file-pdf"
            />
            <fa-icon
              v-else-if="isFileAudio"
              icon="i-fal-file-audio"
            />
            <fa-icon
              v-else
              icon="i-fal-file"
            />
          </div>

          <div
            class="line-clamp-3 mb-1 flex-1 break-all text-center text-2.5"
            :title="filename"
          >
            {{ filename }}
          </div>
          <div class="w-full flex items-center gap-1 text-2.5">
            <div class="text-2">
              <template v-if="isFileAudio && 'filetype' in file">
                <fa-icon icon="i-fas-play" />
              </template>
              <template v-else>
                <fa-icon icon="i-far-arrow-down-to-line" />
              </template>
            </div>
            <div class="flex-1 text-left text-2.5">
              <template v-if="isFileAudio && 'id' in file">
                {{ fmt.duration(file.duration ?? 0) }}
              </template>
              <template v-else>
                {{ fmt.byte(file.size) }}
              </template>
            </div>
            <div class="text-2.5">
              {{ filename.split('.').pop()?.substring(0, 5) }}
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="isFileVideo"
        class="absolute inset-0 flex-center flex-col bg-black bg-opacity-70 p-2 text-4xl text-white"
      >
        <div class="flex-center flex-1">
          <fa-icon icon="i-fas-play" />
        </div>
        <div class="text-xs">
          <template v-if="'duration' in file">
            {{ fmt.duration(file.duration ?? 0) }}
          </template>
        </div>
      </div>
    </app-button>
    <div
      v-else
      class="relative size-32 flex-center flex-col gap-2 rounded bg-white p-2 ring-1 ring-black ring-opacity-10 drop-shadow"
    >
      <div class="text-xs">{{ file.progress }}%</div>
      <div class="h-2 w-full overflow-hidden border border-primary-400 rounded-lg bg-primary-100">
        <div
          class="h-2 bg-primary-300"
          :style="{ width: file.progress + '%' }"
        ></div>
      </div>
    </div>

    <div
      v-if="canDelete && 'progress' in file === false"
      class="absolute -right-2 -top-2"
    >
      <app-button
        class="size-6 flex-center rounded-full bg-gray-50 text-xs text-gray-600 ring-1 ring-black ring-opacity-20 drop-shadow hover:text-gray-900"
        @click="emit('remove')"
      >
        <fa-icon icon="i-far-xmark" />
      </app-button>
    </div>
  </div>
</template>
