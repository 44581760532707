export default [
  {
    path: '',
    component: () => import('@/views/app/banner/page-list.vue'),
    name: 'banner list',
    meta: { title: 'Daftar Banner' },
  },
  {
    path: 'baru',
    component: () => import('@/views/app/banner/page-create.vue'),
    name: 'banner create',
    meta: { title: 'Buat Banner' },
  },
  {
    path: 'edit/:id',
    component: () => import('@/views/app/banner/page-edit.vue'),
    props: true,
    name: 'banner edit',
    meta: { title: 'Edit Banner' },
  },
]
