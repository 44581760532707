import { watchDebounced } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { useApi } from '@/functions/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useSearchGlobalStore } from '@/stores/search-global'

export function useDashboard() {
  const route = useRoute()
  const router = useRouter()

  const api = useApi()
  const auth = useAuthStore()
  const app = useAppStore()

  type DashboardInitResponse = {
    user: AuthenticatedUser
    sidebar: {
      count_project_request: number
    }
    storage: {
      sum_usage: number
      count_files: number
    }
  }

  const shouldRedirectToHome = () => {
    // route where authenticated user should not be able to access
    return ['login', 'forget password', 'forbidden'].includes(route.name as string)
  }

  const init = async () => {
    try {
      const response = await api.GET<DashboardInitResponse>('admin/app/init')
      auth.setUser(response.user)
      app.setSidebar(response.sidebar)
      app.setStorage(response.storage)

      if (response.user.require_new_password) {
        await router.replace({ name: 'set new password' })
      } else if (shouldRedirectToHome()) {
        await router.replace('/' + (route.query.r ?? ''))
      }

      // when user authenticated but opening login page
      // settimeout to prevent flashing login page, let
      // the redirect above to take effect first
      auth.setAuthenticated(1)
    } catch (error) {
      console.log(error)
      setTimeout(() => {
        // when user not authenticated but opening page other than
        // login, forget password, forbidden, let the redirect
        // to login page take effect first
        auth.setAuthenticated(0)
      }, 200)
    }
  }

  return {
    init,
  }
}

export function useDashboardSearch() {
  const searchGlobal = useSearchGlobalStore()
  const api = useApi()

  const getData = async () => {
    searchGlobal.loading = true
    try {
      const response = await api.GET<SearchGlobal>('admin/app/search', {
        search: searchGlobal.search,
      })
      searchGlobal.admins = response.admins ?? []
      searchGlobal.customers = response.customers ?? []
      searchGlobal.technicians = response.technicians ?? []
      searchGlobal.projects = response.projects ?? []
      searchGlobal.projectRequests = response.project_requests ?? []
    } finally {
      searchGlobal.loading = false
    }
  }

  watchDebounced(
    () => searchGlobal.search,
    () => {
      getData()
    },
    { debounce: 500 },
  )
}
