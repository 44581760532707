export const error404 = {
  path: '/:pathMatch(.*)*',
  component: () => import('@/views/errors/error-404.vue'),
  meta: { title: 'Halaman Tidak Ditemukan' },
}

export const error403 = {
  path: '/akses-ditolak',
  name: 'forbidden',
  component: () => import('@/views/errors/error-403.vue'),
  meta: { title: 'Akses Ditolak' },
}
