<script setup lang="ts">
  import { ref, useTemplateRef } from 'vue'
  import { filereader } from '@/functions/img'

  const props = defineProps<{
    label?: string
    width: number
    aspectX: number
    aspectY: number
    noNeedCropper?: boolean
    imageCropperLabel: string
    fitMode?: 'contain' | 'fill'
  }>()

  const model = defineModel<string | undefined>({ default: undefined })
  const uncroppedSrc = ref<string | null>(model.value ?? null)
  const canvasData = ref<Cropper.CanvasData>()
  const refImgCropper = useTemplateRef('refImgCropper')

  const imageUploaded = async (files: File[]) => {
    if (files.length) {
      const imageBase64 = await filereader(files[0])
      if (props.noNeedCropper) {
        imageCropped(imageBase64 ?? '')
        return
      }

      if (imageBase64) {
        uncroppedSrc.value = imageBase64
        openImgCropper(imageBase64)
      }
    }
  }
  const openImgCropper = (imgSrc: string) => {
    refImgCropper.value?.open(imgSrc, canvasData.value)
  }
  const imageCropped = (croppedSrc: string | undefined, canvas?: Cropper.CanvasData) => {
    model.value = croppedSrc
    canvasData.value = canvas
  }

  const deleteImage = () => {
    model.value = undefined
    uncroppedSrc.value = null
    canvasData.value = undefined
  }
</script>

<template>
  <app-input-file
    accept="image/*"
    @change="imageUploaded"
  >
    <template #default="{ open }">
      <div class="group relative inline-block leading-0">
        <app-button
          :style="{
            width: `${width}px`,
            height: `${(width * aspectY) / aspectX}px`,
          }"
          @click="open()"
        >
          <app-img
            v-if="model"
            :style="{
              width: `${width}px`,
              height: `${(width * aspectY) / aspectX}px`,
            }"
            :fit-mode="fitMode"
            :src="model"
          />
          <!-- plus icon size depends to the container -->
          <div
            v-else
            class="size-full flex-center"
          >
            <!-- create square at the center, plus size is 2/5 (40%) of the container size -->
            <div class="relative aspect-square h-full flex-center">
              <div class="absolute h-0.5 w-2/5 bg-gray-300"></div>
              <div class="absolute h-2/5 w-0.5 bg-gray-300"></div>
            </div>
          </div>
        </app-button>
        <div class="pointer-events-none absolute inset-0 border"></div>
        <div
          v-if="model"
          class="absolute bottom-2 right-2 hidden items-center group-hover:flex space-x-1"
        >
          <app-button
            v-if="model?.startsWith('http')"
            class="btn-low-priority btn-edit"
            title="Ganti Gambar"
            @click="open()"
          >
            <fa-icon icon="i-far-folder-open" />
          </app-button>
          <app-button
            v-else-if="uncroppedSrc"
            class="btn-low-priority btn-edit"
            title="Crop Gambar"
            @click="openImgCropper(uncroppedSrc)"
          >
            <fa-icon icon="i-far-crop" />
          </app-button>
          <app-button
            class="btn-low-priority btn-edit"
            title="Hapus Gambar"
            @click="deleteImage"
          >
            <fa-icon icon="i-fas-xmark" />
          </app-button>
        </div>
      </div>
    </template>
  </app-input-file>

  <app-img-cropper
    ref="refImgCropper"
    :title="imageCropperLabel"
    :width="width"
    :aspect-x="aspectX"
    :aspect-y="aspectY"
    :fit-mode="fitMode"
    @save="imageCropped"
  />
</template>

<style lang="postcss" scoped>
  .btn-edit {
    @apply bg-white !size-5 !p-0 !text-2.5;
  }
</style>
