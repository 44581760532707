<script setup lang="ts">
  import { randomId } from '@/functions'

  defineOptions({
    inheritAttrs: false,
  })
  const id = randomId()
  const model = defineModel<string>({ required: true })
</script>

<template>
  <app-input
    v-model="model"
    v-bind="$attrs"
    :list="id"
  />
  <datalist :id="id">
    <slot></slot>
  </datalist>
</template>
