import type { App } from 'vue'
import AppInputAutocomplete from './app-input-autocomplete.vue'
import AppInputFile from './app-input-file.vue'
import AppInputImage from './app-input-image.vue'
import AppInputNumeric from './app-input-numeric.vue'
import AppInputPassword from './app-input-password.vue'
import AppInputTel from './app-input-tel.vue'
import AppInput from './app-input.vue'

export default {
  install(app: App) {
    app.component('AppInputAutocomplete', AppInputAutocomplete)
    app.component('AppInput', AppInput)
    app.component('AppInputFile', AppInputFile)
    app.component('AppInputImage', AppInputImage)
    app.component('AppInputNumeric', AppInputNumeric)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppInputTel', AppInputTel)
  },
}

declare module 'vue' {
  export interface GlobalComponents {
    AppInputAutocomplete: typeof AppInputAutocomplete
    AppInput: typeof AppInput
    AppInputImage: typeof AppInputImage
    AppInputFile: typeof AppInputFile
    AppInputNumeric: typeof AppInputNumeric
    AppInputPassword: typeof AppInputPassword
    AppInputTel: typeof AppInputTel
  }
}
