<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    modelValue: boolean
    loading?: boolean
    disabled?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
  }>()

  const checked = computed({
    set(value) {
      emit('update:modelValue', value)
    },
    get: () => props.modelValue,
  })
</script>

<template>
  <div class="outer">
    <label
      class="switch"
      :class="{ changing: loading, checked: checked, disabled: disabled }"
    >
      <input
        :value="checked"
        type="checkbox"
        :disabled="disabled"
        class="peer absolute opacity-0"
        @input="checked = !checked"
      />
      <span class="absolute inset-0 peer-focus-visible:ring"></span>
      <span class="circle"></span>
      <span class="sr-only">switch</span>
    </label>
  </div>
</template>

<style lang="postcss" scoped>
  /* Styles from https://www.youtube.com/watch?v=0ZXkFKlS47I */
  .outer {
    --circle-size: 1rem; /* 16px */
    --switch-width: 1.75rem; /* 28px */
    --switch-border: 0.125rem; /* 2px */
    --color-active: theme('colors.primary.500');
    --color-inactive: theme('colors.gray.300');

    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: var(--circle-size);
    width: var(--switch-width);
    position: relative;
    overflow: hidden;
  }
  .switch {
    display: inline-block;
    height: var(--circle-size);
    width: var(--switch-width);
    background-color: var(--color-inactive);
    border-radius: calc(var(--circle-size) - (2 * var(--switch-border)));
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .switch .circle {
    height: calc(var(--circle-size));
    width: calc(var(--circle-size));
    display: block;
    position: absolute;
    background-color: white;
    border-radius: calc(var(--circle-size) - (2 * var(--switch-border)));
    border-width: var(--switch-border);
    border-style: solid;
    border-color: var(--color-inactive);
    left: 0;
    transition: all 0.3s ease;
  }
  .checked.switch {
    background-color: var(--color-active);
  }
  .checked.switch .circle {
    border-color: var(--color-active);
    left: calc(var(--switch-width) - var(--circle-size));
  }
  .changing.switch {
    width: var(--circle-size);
    /* background-color: #9b999b; */
    pointer-events: none;
  }
  .changing.switch .circle {
    border-color: var(--color-inactive) !important;
    border-top-color: var(--color-active) !important;
    left: calc((var(--switch-width) - var(--circle-size)) / 2);
    animation: spin 1s ease infinite;
  }
  .switch.disabled {
    cursor: not-allowed;
    background-color: var(--color-inactive);
    filter: opacity(0.5);
  }
  .switch.disabled .circle {
    border-color: var(--color-inactive);
  }
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
</style>
