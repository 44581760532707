<script setup lang="ts">
  import DOMPurify from 'dompurify'
  import { marked } from 'marked'
  import { ref, watchEffect } from 'vue'
  import './markdown.css'

  const props = defineProps<{
    text: string
  }>()

  const cleanHtml = ref('')
  watchEffect(async () => {
    const dirtyHtml = await marked(props.text)
    cleanHtml.value = DOMPurify.sanitize(dirtyHtml, {
      USE_PROFILES: { html: true },
    })
  })
</script>

<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="markdown"
      v-html="cleanHtml"
    ></div>
  </div>
</template>
