import { type App, onBeforeUnmount, ref } from 'vue'
import AppModalDescription from './app-modal-description.vue'
import AppModalFooter from './app-modal-footer.vue'
import AppModalForm from './app-modal-form.vue'
import AppModalTitle from './app-modal-title.vue'
import AppModal from './app-modal.vue'

// used for component that has modal
export function useAppModal<T extends unknown[]>(onBeforeOpen?: (...args: T) => void) {
  const modal = ref<AppModal>()
  // calling open with any arguments will be passed to onBeforeOpen callback
  const open = (...args: T) => {
    onBeforeOpen?.(...args)
    modal.value?.open()
  }
  const close = () => {
    modal.value?.close()
  }
  return { modal, open, close }
}

// used for new modal component
export function useModal() {
  const { disableOverflow, reenableOverflow } = useDocumentOverflow()
  const isOpen = ref(false)

  const open = () => {
    isOpen.value = true
    disableOverflow()
    addListener()
  }
  const close = () => {
    isOpen.value = false
    reenableOverflow()
    removeListener()
  }

  const { addListener, removeListener } = useEscListener(close)

  onBeforeUnmount(close)

  return { isOpen, open, close }
}

const useDocumentOverflow = () => {
  const disableOverflow = () => {
    document.body.style.overflowY = 'hidden'
  }
  const reenableOverflow = () => {
    document.body.style.overflowY = ''
    // delay because of vue lightbox
    setTimeout(() => {
      document.body.style.overflowY = ''
    }, 200)
  }
  return {
    disableOverflow,
    reenableOverflow,
  }
}

export const useEscListener = (callback: () => void) => {
  const onEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callback()
    }
  }
  const addListener = () => {
    window.addEventListener('keydown', onEscape)
  }
  const removeListener = () => {
    window.removeEventListener('keydown', onEscape)
  }

  return { addListener, removeListener }
}

export default {
  install(app: App) {
    app.component('AppModal', AppModal)
    app.component('AppModalDescription', AppModalDescription)
    app.component('AppModalForm', AppModalForm)
    app.component('AppModalFooter', AppModalFooter)
    app.component('AppModalTitle', AppModalTitle)
  },
}

declare module 'vue' {
  export interface GlobalComponents {
    AppModal: typeof AppModal
    AppModalDescription: typeof AppModalDescription
    AppModalForm: typeof AppModalForm
    AppModalFooter: typeof AppModalFooter
    AppModalTitle: typeof AppModalTitle
  }
}

declare global {
  type AppModal = InstanceType<typeof AppModal>
}
