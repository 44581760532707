export default [
  {
    path: '',
    component: () => import('@/views/app/project-request/list/page-list.vue'),
    name: 'project request list',
    meta: { title: 'Daftar Request Layanan' },
  },
  {
    path: ':id/:name?',
    component: () => import('@/views/app/project-request/detail/page-detail.vue'),
    name: 'project request detail',
    meta: { title: 'Rincian Request Layanan' },
  },
]
