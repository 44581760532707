<script setup lang="ts">
  import { debouncedWatch } from '@vueuse/core'
  import 'cropperjs/dist/cropper.css'
  import { ref, useTemplateRef } from 'vue'
  import { filereader } from '@/functions/img'
  import { uiAvatarSrc } from '@/functions/user-photo'

  const props = defineProps<{
    userName?: string
  }>()

  const src = defineModel<string | null>('src')

  const emit = defineEmits<{
    (e: 'cancel'): void
  }>()

  const size = 256
  const defaultUserName = 'Masa Group'
  const defaultSrc = ref(uiAvatarSrc(props.userName || defaultUserName))
  debouncedWatch(
    () => props.userName,
    () => {
      defaultSrc.value = uiAvatarSrc(props.userName || defaultUserName)
    },
    { debounce: 1000 },
  )

  const modal = useTemplateRef('modal')
  const inputFileImage = ref<HTMLInputElement>()

  const uncroppedSrc = ref('')
  const canvasData = ref<Cropper.CanvasData>()
  const imageChange = async (files: File[]) => {
    const imageBase64 = await filereader(files[0])
    if (imageBase64) {
      modal.value?.open(imageBase64)
      uncroppedSrc.value = imageBase64
    }
  }
  const onSave = (croppedSrc: string | undefined, canvas?: Cropper.CanvasData) => {
    src.value = croppedSrc
    canvasData.value = canvas
  }
  const cancelChange = () => {
    emit('cancel')
    if (inputFileImage.value) {
      inputFileImage.value.value = ''
    }
  }
</script>

<template>
  <div>
    <app-img-cropper
      id="photo-cropper"
      ref="modal"
      title="Edit Foto"
      :width="size"
      :aspect-x="1"
      :aspect-y="1"
      @save="onSave"
    />

    <app-input-file
      v-slot="{ open }"
      accept="image/*"
      @change="imageChange"
    >
      <div class="mb-2">
        <app-button
          class="max-w-64 w-full"
          @click="open()"
        >
          <app-img
            :src="src ?? defaultSrc"
            class="aspect-square max-h-64 max-w-64 border rounded-full"
          />
        </app-button>
      </div>
    </app-input-file>

    <div
      v-if="src && !src.startsWith('http')"
      class="max-w-64 w-full flex flex-col items-center space-y-2"
    >
      <app-button
        class="btn-primary w-28"
        @click="modal?.open(uncroppedSrc, canvasData)"
      >
        Edit Foto
      </app-button>
      <app-button
        class="btn-low-priority w-28"
        @click="cancelChange"
      >
        Hapus Foto
      </app-button>
    </div>
  </div>
</template>

<style lang="postcss">
  #photo-cropper {
    .cropper-view-box,
    .cropper-face {
      @apply rounded-full;
    }
  }
</style>
