<script setup lang="ts">
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'

  defineProps<{
    placeholder?: string
    loading?: boolean
  }>()

  const route = useRoute()
  const router = useRouter()

  const text = ref((route.query.cari ?? '') as string)

  const submitSearch = () => {
    router.push({
      query: {
        // maintain other query params, but reset pagination
        ...route.query,
        hlm: undefined,
        // append or remove search to query params
        cari: text.value ? text.value : undefined,
      },
    })
  }
</script>

<template>
  <app-search
    v-model="text"
    :placeholder="placeholder"
    :loading="loading && !!text"
    @submit="submitSearch"
  />
</template>
