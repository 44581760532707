import type { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/stores/app'
import auditRoute from './audit'
import authRoutes from './auth'
import bannerRoutes from './banner'
import changelogRoute from './changelog'
import customerRoutes from './customer'
import dashboardRoute from './dashboard'
import { error403, error404 } from './error'
import masterRoutes from './master'
import notificationRoutes from './notification'
import projectRoutes from './project'
import quotationRoutes from './project-quotation'
import projectRequestRoutes from './project-request'
import searchRoute from './search'
import settingRoutes from './setting'
import shoppingListRoutes from './shopping-list'
import adminRoutes from './user-admin'

const routes: RouteRecordRaw[] = [
  layoutApp('', [dashboardRoute, changelogRoute, searchRoute, auditRoute]),
  layoutApp('/pengaturan', settingRoutes),
  layoutApp('/pengaturan/admin', adminRoutes),
  layoutApp('/banner', bannerRoutes),
  layoutApp('/pelanggan', customerRoutes),
  layoutApp('/penawaran', quotationRoutes),
  layoutApp('/proyek', projectRoutes),
  layoutApp('/daftar-belanja', shoppingListRoutes),
  layoutApp('/request-layanan', projectRequestRoutes),
  layoutApp('/notifikasi', [notificationRoutes]),
  ...masterRoutes,
  authRoutes,
  error404,
  error403,
]

export function layoutApp(path: string, children: RouteRecordRaw[]): RouteRecordRaw {
  return {
    path,
    children,
    component: () => import('@/views/app/layout-app.vue'),
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, _, next) => {
  const app = useAppStore()

  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  app.setPageTitle(to.meta.title)

  next()
})

export default router

/**
 * Make sure all routes has meta with these attributes.
 */
declare module 'vue-router' {
  interface RouteMeta {
    title: string
  }
}
