import { layoutApp } from '@/routes'

export default [
  layoutApp('/master', [
    {
      path: 'lokasi',
      component: () => import('@/views/app/master/location/page-list.vue'),
      name: 'master location list',
      meta: { title: 'Daftar Lokasi' },
    },
    {
      path: 'tipe-bangunan',
      component: () => import('@/views/app/master/building/page-list.vue'),
      name: 'master building list',
      meta: { title: 'Daftar Tipe Bangunan' },
    },
  ]),
  // {
  //   path: 'tipe-jasa',
  //   component: () => import('@/views/app/master/service-category/page-list.vue'),
  //   name: 'master service category list',
  //   meta: { title: 'Daftar Kategori Jasa' },
  // },
  layoutApp('/master/jasa', [
    {
      path: '',
      component: () => import('@/views/app/master/service/list/page-list.vue'),
      name: 'service list',
      meta: { title: 'Daftar Jasa' },
    },
    {
      path: 'tambah',
      component: () => import('@/views/app/master/service/create/page-create.vue'),
      name: 'service create',
      meta: { title: 'Tambah Jasa' },
    },
    {
      path: ':id/edit',
      component: () => import('@/views/app/master/service/edit/page-edit.vue'),
      name: 'service edit',
      props: true,
      meta: { title: 'Rincian Jasa' },
    },
  ]),
]
