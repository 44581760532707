import type { App } from 'vue'
import AppDropdownItem from './app-dropdown-item.vue'
import AppDropdown from './app-dropdown.vue'
import AppEllipsisMenu from './app-ellipsis-menu.vue'

export default {
  install(app: App) {
    app.component('AppDropdown', AppDropdown)
    app.component('AppDropdownItem', AppDropdownItem)
    app.component('AppEllipsisMenu', AppEllipsisMenu)
  },
}
declare module 'vue' {
  export interface GlobalComponents {
    AppDropdown: typeof AppDropdown
    AppDropdownItem: typeof AppDropdownItem
    AppEllipsisMenu: typeof AppEllipsisMenu
  }
}
