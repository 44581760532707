export default [
  {
    path: '',
    component: () => import('@/views/app/project/list/page-list.vue'),
    name: 'project list',
    meta: { title: 'Daftar Proyek' },
  },
  {
    path: 'create',
    component: () => import('@/views/app/project/create/page-create.vue'),
    name: 'project create',
    meta: { title: 'Buat Proyek' },
  },
  {
    path: ':id/edit',
    component: () => import('@/views/app/project/edit/page-edit.vue'),
    name: 'project edit',
    meta: { title: 'Edit Proyek' },
  },
  {
    path: ':id/:name?',
    component: () => import('@/views/app/project/detail/page-detail.vue'),
    name: 'project detail',
    meta: { title: 'Rincian Proyek' },
  },
]
